import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PurchaseOrderDocumentStatus } from '../api/purchase-orders'
import { Project, WarehouseId } from '../models/models'
import { RootState } from './store'

export enum TransferFilter {
    None = 'None',
    Dispatch = 'Dispatch',
    Assembly = 'Assembly',
}

export enum FilterDispatchDate {
    Today = 'Today',
    Upcoming = 'Upcoming',
}

export interface LogisticsWorksheetState {
    activeView: LogisticsWorksheetView
    filterProjectName: string
    filterSavNumber: string
    filterCallOffId: string
    filterAssemblyId: string
    purchaseOrderCreators: string[]
    purchaseOrderSuppliers: string[]
    projectManagers: Project[]
    callOffProjectIds: number[]
    filterDispatchDate: FilterDispatchDate
    filterWarehouseId: WarehouseId
    filterDocumentNo: string
    filterPurchaseOrderCreator: string
    filterPurchaseOrderSupplier: string
    filterProjectManager: number | undefined
    filterIsPurchaseOrderActivated: boolean | undefined
    filterPurchaseOrderStatus: PurchaseOrderDocumentStatus | undefined
}

export enum LogisticsWorksheetView {
    Booking = 'Booking',
    Transfer = 'Transfer',
    Dispatch = 'Dispatch',
}

const initialState: LogisticsWorksheetState = {
    filterDispatchDate: FilterDispatchDate.Upcoming,
    purchaseOrderCreators: [],
    purchaseOrderSuppliers: [],
    projectManagers: [],
    callOffProjectIds: [],
    activeView: LogisticsWorksheetView.Booking,
    filterProjectName: '',
    filterSavNumber: '',
    filterCallOffId: '',
    filterAssemblyId: '',
    filterWarehouseId: WarehouseId.Monument,
    filterDocumentNo: '',
    filterPurchaseOrderCreator: '',
    filterPurchaseOrderSupplier: '',
    filterProjectManager: undefined,
    filterIsPurchaseOrderActivated: undefined,
    filterPurchaseOrderStatus: undefined,
}

export const logisticsWorksheetSlice = createSlice({
    name: 'logistics-worksheet',
    initialState,
    reducers: {
        resetState(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
        resetFilters(state) {
            state.filterProjectName = ''
            state.filterSavNumber = ''
            state.filterCallOffId = ''
            state.filterWarehouseId = WarehouseId.Monument
            state.filterDocumentNo = ''
            state.filterPurchaseOrderCreator = ''
            state.filterPurchaseOrderSupplier = ''
            state.filterAssemblyId = ''
            state.filterIsPurchaseOrderActivated = undefined
            state.filterPurchaseOrderStatus = undefined
        },
        setFilterProjectName(state, action: PayloadAction<string>) {
            state.filterProjectName = action.payload
        },
        setFilterAssemblyId(state, action: PayloadAction<string>) {
            state.filterAssemblyId = action.payload
        },
        setFilterSavNumber(state, action: PayloadAction<string>) {
            state.filterSavNumber = action.payload
        },
        setFilterCallOffId(state, action: PayloadAction<string>) {
            state.filterCallOffId = action.payload
        },
        setFilterWarehouseId(state, action: PayloadAction<WarehouseId>) {
            state.filterWarehouseId = action.payload
        },
        setFilterPurchaseOrderCreator(state, action: PayloadAction<string>) {
            state.filterPurchaseOrderCreator = action.payload
        },
        setFilterPurchaseOrderSupplier(state, action: PayloadAction<string>) {
            state.filterPurchaseOrderSupplier = action.payload
        },
        setFilterIsPurchaseOrderActivated(state, action: PayloadAction<boolean | undefined>) {
            state.filterIsPurchaseOrderActivated = action.payload
        },
        setFilterDocumentNo(state, action: PayloadAction<string>) {
            state.filterDocumentNo = action.payload
        },
        setPurchaseOrderSuppliers(state, action: PayloadAction<string[]>) {
            state.purchaseOrderSuppliers = action.payload
        },
        setProjectManagers(state, action: PayloadAction<Project[]>) {
            state.projectManagers = action.payload
        },
        setCallOffProjectIds(state, action: PayloadAction<number[]>) {
            state.callOffProjectIds = action.payload
        },
        setPurchaseOrderCreators(state, action: PayloadAction<string[]>) {
            state.purchaseOrderCreators = action.payload
        },
        setFilterPurchaseOrderStatus(
            state,
            action: PayloadAction<PurchaseOrderDocumentStatus | undefined>
        ) {
            state.filterPurchaseOrderStatus = action.payload
        },
        setFilterProjectManager(state, action: PayloadAction<number | undefined>) {
            state.filterProjectManager = action.payload
        },
        setFilterDispatchDate(state, action: PayloadAction<FilterDispatchDate>) {
            state.filterDispatchDate = action.payload
        },
        setActiveView(state, action: PayloadAction<LogisticsWorksheetView>) {
            state.activeView = action.payload
            if (action.payload === LogisticsWorksheetView.Dispatch) {
                state.filterDispatchDate = FilterDispatchDate.Upcoming
            }
        },
    },
})

export const selectActiveView = (state: RootState) => state.callOff.activeView

export const {
    resetState,
    setCallOffProjectIds,
    setPurchaseOrderCreators,
    setPurchaseOrderSuppliers,
    setProjectManagers,
    setFilterDispatchDate,
    setActiveView,
    setFilterProjectName,
    resetFilters,
    setFilterSavNumber,
    setFilterCallOffId,
    setFilterWarehouseId,
    setFilterPurchaseOrderCreator,
    setFilterPurchaseOrderSupplier,
    setFilterAssemblyId,
    setFilterIsPurchaseOrderActivated,
    setFilterDocumentNo,
    setFilterPurchaseOrderStatus,
    setFilterProjectManager,
} = logisticsWorksheetSlice.actions

export default logisticsWorksheetSlice.reducer
